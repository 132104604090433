import React from "react";
import { Text, Image, Link } from '@sitecore-jss/sitecore-jss-react';
import {NavLink} from "react-router-dom";
import i18next from 'i18next';

class Footer extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {SocialLinks} = this.props.fields;
    const {MenuLinks} = this.props.fields;
    return(
      <footer className="main-ft">
        <div className="row_custom_small top-ft">
          <div className="col-md-8 col-xs-12">
            <p>
              <NavLink to={"/"} >
                <img className="logo img_custom_full" src={this.props.fields.Logo && this.props.fields.Logo.value.src} />
              </NavLink>
            </p>
           <ul className="col-md-12 sidebar_bot pl-0 pb-0">
             {this.props.fields && this.props.fields.FooterMultilevel.map((footerMulti) => (
                 footerMulti.fields && footerMulti.fields.HeaderMenu.map((mainMenu, mainindex) =>
                    <li id={`mainind_${mainindex}`} className="col-md-3 pl-0 pr-0" key={`foot-${mainindex}`}>
                   {mainMenu.fields.Title &&
                      mainMenu.fields.InternalLink && mainMenu.fields.InternalLink.value.href != '' &&
                       (
                           mainMenu.fields.InternalLink.value.href && mainMenu.fields.InternalLink.value.href.startsWith("http") === true ?
                               <Link field={mainMenu.fields.InternalLink} className="helv_rom"/>
                               :
                               <NavLink to={mainMenu.fields.InternalLink.value.href} className="helv_rom">
                                 <Text field={mainMenu.fields.Title} />
                               </NavLink>
                       )
                   }
                   {mainMenu.fields.SubMenuLinks.length > 0 &&
                     <ul id={`subind_${mainindex}`} className="sub-menu">
                       {mainMenu.fields.SubMenuLinks.map((subMenu, subindex) => (
                          <li key={`sub_${subindex}`} className="menu-item">
                            {subMenu.fields.InternalLink && subMenu.fields.InternalLink.value.href != '' &&
                             (
                               subMenu.fields.InternalLink.value.href && subMenu.fields.InternalLink.value.href.startsWith("http") === true ?
                                 <Link field={subMenu.fields.InternalLink} className="helv_lt"/>
                                 :
                                 <NavLink to={subMenu.fields.InternalLink.value.href} className="helv_lt">
                                   <Text field={subMenu.fields.Title} />
                                 </NavLink>
                             )}
                           </li>
                       ))}
                     </ul>
                    }
                 </li>
                 )
                ))
             }
           </ul>
          </div>
          <div className="col-md-4 col-xs-12">
            <p className="link_social mt-0">
              { SocialLinks &&
              SocialLinks.map((listItem, index) => (
                  <React.Fragment key={index}>
                  {listItem.fields.InternalLink && listItem.fields.InternalLink.value.href.startsWith("http") === true ?
                    <a href={listItem.fields.InternalLink.value.href} target="_blank" key={index}>
                      <img src={listItem.fields.Image && listItem.fields.Image.value.src} className="img_custom_full"/>
                    </a>
                    :
                      <NavLink to={listItem.fields.InternalLink.value.href} target="_blank" key={index}>
                        <img src={listItem.fields.Image && listItem.fields.Image.value.src} className="img_custom_full"/>
                      </NavLink>
                  }
                  </React.Fragment>
              ))}
            </p>
            <p className="mt-0">
              <Text className="link_stext" field={this.props.fields.Text} tag="strong" />
            </p>
            <p className="link_contact">
              <a href={this.props.fields.TelephoneNumber ? this.props.fields.TelephoneNumber.value : {}} target="_blank" className="helv_lt"><i className="fas fa-phone-alt"></i>{i18next.t("ConsumerLabel")} <Text field={this.props.fields.TelephoneNumber} /></a><br />
              <a href={this.props.fields.EmailAddress ? this.props.fields.EmailAddress.value : {}} target="_blank" className="helv_lt"><i className="far fa-envelope" aria-hidden="true"></i>{i18next.t("EmailLabel")}</a>
            </p>
          </div>                       
        </div>

        <div className="row_custom_small bottom-ft">
          <div className="col-md-12 pl-0">
            <p>
              <span className="helv_rom">{i18next.t("CopyrightLabel")}</span>
              { MenuLinks &&    
                MenuLinks.map((listItem, index) => (
                    <React.Fragment key={index}>
                      {listItem.fields.InternalLink.value.href && listItem.fields.InternalLink.value.href.startsWith("http") === true ?
                          <Link field={listItem.fields.InternalLink} key={index} className="helv_rom" />
                        :
                          <NavLink to={listItem.fields.InternalLink.value.href} key={index} className="helv_rom">
                            {listItem.fields.InternalLink.value.text}
                          </NavLink>
                      }
                    </React.Fragment>
                ))
              }
              <a className={"helv_rom optanon-toggle-display optanon-show-settings"}>{i18next.t("CookieConfigurationLabel")}</a>
            </p>
          </div>
        </div>
      </footer>


    );
  }
}

export default Footer;
