import React from "react";
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import ParentRoute from '../../Utils/ParentRoute';


const Breadcrumbs = (props) => {
  return(
      <div className="row_custom_small display__bread">
        <div className="col-md-12 pt-0 pb-0 helv_md">
          <ParentRoute CurrentId={props.sitecoreContext.itemId} CurrentItemName={props.sitecoreContext.route.displayName} />
        </div>
      </div>
    );
};
export default withSitecoreContext()(Breadcrumbs);
