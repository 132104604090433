import React from "react";
import separatorImg from '../../assets/img/separa.png'

const RowSeparator = () => (
  <div className="Separa-cn">
      <img src={separatorImg}  alt="" className="img_custom_full"/>
  </div>
);

export default RowSeparator;
