import React from "react";
import {Placeholder,withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';
import { StickyContainer } from 'react-sticky';
import i18next from 'i18next';
import { observeProductFinder } from '../Tagging';

const displayNone = {
  display: 'none'
}
const displayBlock = {
  display: 'block'
}

class ProductFinderLayoutWrapper extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      facets: [],
      filters: [],
      resetFilters: false,
      rangeFilter: ["*","*"],
      updateRange: true,
      keyword: "",
      resetSearch: false,
      selectedCategoriesText: "",
      showProducts: false,
      sidebar: false,
      pageTag: "",
      urlTagId: "",
      categoriesSession: [],
      categoriesSessionNames: "",
    }
    this.onFacetsChange = this.onFacetsChange.bind(this);
    this.onFiltersChange = this.onFiltersChange.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.handleShowProducts = this.handleShowProducts.bind(this);
    this.onRangeChange = this.onRangeChange.bind(this);
    this.handleUpdateOnCategory = this.handleUpdateOnCategory.bind(this);
  }

  onFacetsChange(facets) {
    this.setState({
      facets: facets,
    })
    this.state = {
      shown: true,
    };
  }
  componentDidMount(){
    const url = window.location.href;
    let filterSearch = "";
    if (url.includes('?search=')) {
      filterSearch = url.substring(url.indexOf("=")+1);
      this.setState({
        keyword: filterSearch
      })
    }
    if (url.includes('?tagId=')){
        let filterId = url.substring(url.indexOf("=")+1)
        this.setState({
            urlTagId: filterId
        })
    }
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();

    if(this.props.sitecoreContext.route.fields.PfTag != null && this.props.sitecoreContext.route.fields.PfTag != "undefined" && this.props.sitecoreContext.route.fields.FilterName != null){
      var FilterName= this.props.sitecoreContext.route.fields.FilterName.value
      this.setState({
        pageTag:FilterName
      })
    }

    observeProductFinder(this.props.sitecoreContext) //GTM
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.showProducts !== this.state.showProducts) {
      this.setState({
        
        showProducts: true
      })
    }
    const url = window.location.href;
    let filterSearch = "";
    if (url.includes('?search=')) {
      filterSearch = url.substring(url.indexOf("=")+1);
    }
    if (prevState.keyword !== filterSearch){
      this.setState({
        
        keyword: filterSearch
      })
    }
    

  }
  onRangeChange(range) {
    this.setState({
      rangeFilter: range,
      updateRange: false,
    })
  }

  handleUpdateOnCategory(categories,categoryNames){
    
    let categoryText = ""
    if(categoryNames.length > 1){
      categoryText =  categoryNames.join(", ")
    }
    else{
      categoryText = categoryNames[0];
    }
    
    
    this.setState({
      categoriesSession: categories,
      categoriesSessionNames: categoryText
    })
  }
  onFiltersChange(filters) {
     
    if (this.state.filters.length === 0) {
      this.setState({
        filters: [...this.state.filters, filters],
        updateRange: true,
        pageTag: ""
      })
    }
    else {
      let found = false;
      let updatedFilters = this.state.filters.map(obj => {
        if (obj.template === filters.template) {
          found = true;
          return(filters);
        }
        else return(obj);
      })
      if (!found) {
        updatedFilters.push(filters);
      }
      this.setState({
        filters: updatedFilters,
        updateRange: true,
        rangeFilter: found ? ["*","*"] : this.state.rangeFilter,
        pageTag: ""
      }) 
    }
  }

  resetFilters() {
    let reset, resetSearch = true;
    if (this.state.resetFilters) reset = false;
    else reset = true;
    if (this.state.resetSearch) resetSearch = false;
    else resetSearch = true;
    this.setState({
      filters: [],
      resetFilters: reset,
      resetSearch: resetSearch,
      rangeFilter: ["*","*"],
      keyword: "",
    });
  }
  handleShowProducts(show){
    if(show == true){
      this.setState({
        
        showProducts: true
      })
    }
  }

  resize() {
    let currentHideNav = (window.innerWidth > 450);
    if (currentHideNav) {
      this.setState({sidebar: true});
    }
  }

  openSidebar(){
    this.setState({
      sidebar: !this.state.sidebar
    });
  }

  render() {
    let sidebar = {
      display: this.state.sidebar === true ? "block" : "none"
    };

    return(
        <div className="container for_tab">
          <span className="filter-bg-finder fadeInRight" style={sidebar} onClick={this.openSidebar.bind(this)} />
          <StickyContainer className="row_custom_small">
            <div className="filters-control display_filter_prod" onClick={this.openSidebar.bind(this)}>
              <i className="fas fa-sliders-h" />
            </div>
            <div className="col-md-3 col-sm-12 sidebar-wrap filters fadeInRight" style={sidebar}>
              <div className="main-sidebar no-border">
                
                <Placeholder name="sidebar-left" rendering={this.props.rendering} facets={this.state.facets} 
                  onFiltersChange={this.onFiltersChange} onRangeChange={this.onRangeChange}
                  resetFilters={this.state.resetFilters} resetSearch={this.state.resetSearch}
                  updateRange={this.state.updateRange} range={this.state.rangeFilter} 
                  handleUpdateOnCategory={this.handleUpdateOnCategory}
                  handleShowProducts={this.handleShowProducts}
                  
                  />
              </div>
            </div>
            <div className={this.state.showProducts == true || this.state.keyword != ""  ? "col-md-9 col-sm-12 main-wrap custom__all_prods all_category_product p-0" : "col-md-9 p-0 all_category_product" } id="products">
                  
                  
                  <div className="row_custom_small" style={(this.props.sitecoreContext.route.fields.PfTag != null && this.props.sitecoreContext.route.fields.PfTag.value != "") || this.state.showProducts == true || this.state.keyword != "" || this.state.urlTagId != ""? displayNone : displayBlock}>
                    <Placeholder name="categoriessubcategories-ph" rendering={this.props.rendering} />
                  </div>


                  <div className="row_custom_small" style={this.state.showProducts == true ||this.state.pageTag != ""|| this.state.keyword != "" ? displayBlock : displayNone}> 
                  {this.state.filters && this.state.filters.length <= 0 && this.state.keyword != "" &&
                  <div className="col-md-12">
                    <p className="title-search__custom">
                    {i18next.t("SearchResultLabel")}
                        <span>{this.state.keyword.replace(/%20/g, " ")}</span>
                    </p>
                  </div>
                  }

                {this.state.pageTag != "" &&
                  <div className="col-md-12">
                    <p className="title-search__custom">
                   
                        <span>{this.state.pageTag}</span>
                    </p>
                  </div>
                  }
                    {this.state.categoriesSessionNames && this.state.categoriesSessionNames.length > 0 &&
                    <div className="col-md-12">
                      <p className="title-search__custom">
                      {i18next.t("SearchResultLabel")}
                        <span>{this.state.keyword.replace(/%20/g, " ")}{ this.state.keyword != "" && this.state.categoriesSessionNames.length > 0 ? ", ": ""}</span>
                        <span>{this.state.categoriesSessionNames}</span>
                      </p>
                    </div>
                    }
                  </div>
                  <div className="row_custom_small list__product__item" style={(this.props.sitecoreContext.route.fields.PfTag != null && this.props.sitecoreContext.route.fields.PfTag.value != "") || this.state.showProducts == true || this.state.keyword != "" || this.state.urlTagId != "" ? displayBlock : displayNone}>
                    <Placeholder name="main-content" rendering={this.props.rendering} filters={this.state.filters}
                                 rangeFilter={this.state.rangeFilter} onFacetsChange={this.onFacetsChange} keyword={this.state.keyword}
                                 categoriesSession={this.state.categoriesSession}
                    />
                  </div>

            </div>
          </StickyContainer>
        </div>
    );
  }
}

export default withSitecoreContext()(ProductFinderLayoutWrapper);
