import React from "react";
import { RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

class RichTextCoponent extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return(
      <div className="row_custom_small">
        <div className="col-md-12 text-center all-articles__custom richtext_custom">
          <RichText field={this.props.fields.Text}/>
        </div>
      </div>
    )
  }
}

export default withSitecoreContext()(RichTextCoponent);
