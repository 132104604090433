import React from "react";
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { withRouter } from 'react-router';
import { handleInternalSearch } from '../Tagging';

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      keywordText: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    console.log(event.target.value);
    this.setState({
      keywordText: event.target.value,
    });
  }
  handleSubmit(event) {
    console.log(event);
    event.preventDefault();
    var link =
      this.props.fields.LinkToProductFinderPage &&
      this.props.fields.LinkToProductFinderPage !== null &&
      this.props.fields.LinkToProductFinderPage.value.href !== ''
        ? this.props.fields.LinkToProductFinderPage.value.href
        : '#';
    this.props.history.push(link + '?search=' + this.state.keywordText);
    handleInternalSearch(this.state.keywordText);
  }

  render() {
    let bgImageSrc =
      this.props.fields.ImgView && this.props.fields.ImgView !== null && this.props.fields.ImgView.value.src !== ''
        ? this.props.fields.ImgView.value.src
        : '';
    const bgImage = {
      backgroundImage: 'url(' + bgImageSrc + ')',
    };
    return (
      <div className="search__custom">
        <div className="row_custom_small" style={bgImage}>
          <div className="col-md-10 text-center col__custom">
            <Text field={this.props.fields.SearchTitle} tag="h1" />
            <form onSubmit={(e) => this.handleSubmit(e)}>
              <fieldset>
                <input type="text" placeholder="Search" value={this.state.keywordText} onChange={this.handleChange} />
                <button type="submit">
                  <i className="fas fa-search" aria-hidden="true"></i>
                </button>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Search);
