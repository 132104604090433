import React from "react";
import { Image, Link, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import i18next from 'i18next';
import { NavLink } from "react-router-dom";
import ReactComponent from "../../ReactComponent";
import { handleAddToCart } from '../Tagging';

class StoreList extends ReactComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
    };
  }

  render() {
    let enableShuffle =
      this.props.sitecoreContext.route.fields.shuffleStoreSalon && this.props.sitecoreContext.route.fields.shuffleStoreSalon.value === true
        ? true
        : false;

    const productName = this.props.sitecoreContext.route.fields.title.value;
    const productId = this.props.sitecoreContext.route.itemId;

    function shuffleItems(array) {
      if (enableShuffle) {
        var currentIndex = array.length,
          temporaryValue,
          randomIndex;

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {
          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex -= 1;

          // And swap it with the current element.
          temporaryValue = array[currentIndex];
          array[currentIndex] = array[randomIndex];
          array[randomIndex] = temporaryValue;
        }
      }
      return array;
    }
    let storeListShuffled = shuffleItems(this.props.fields.StoreList);

    return (
      <div className="dropdown">
        <a
          className="btn btn-secondary dropdown-toggle text-uppercase helv_std"
          role="button"
          id="dropdownfirst"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false">
          {i18next.t('ShopOnlineLabel')}
        </a>
        <div className="dropdown-menu" aria-labelledby="#dropdownfirst">
          {this.props.fields &&
            this.props.fields.StoreList &&
            storeListShuffled &&
            storeListShuffled.map((storeItem, storeIndex) =>
              storeItem.fields && storeItem.fields.StoreLink && storeItem.fields.StoreLink.value.href.startsWith('http') === true ? (
                <Link
                  onClick={() => handleAddToCart(productName, productId, storeItem.fields.Brand.fields.StoreName.value, storeItem.url.split('/')[2])}
                  className="dropdown-item"
                  field={storeItem.fields && storeItem.fields.StoreLink}
                  key={storeIndex}>
                  <img
                    src={
                      storeItem.fields &&
                      storeItem.fields.Brand &&
                      storeItem.fields.Brand.fields &&
                      storeItem.fields.Brand.fields.StoreImage &&
                      storeItem.fields.Brand.fields.StoreImage.value.src
                    }
                  />
                </Link>
              ) : (
                <NavLink
                  onClick={() => handleAddToCart(productName, productId, storeItem.fields.Brand.fields.StoreName.value, storeItem.url.split('/')[2])}
                  to={storeItem.fields.StoreLink.value.href}
                  className="dropdown-item"
                  key={storeIndex}>
                  <img
                    src={
                      storeItem.fields &&
                      storeItem.fields.Brand &&
                      storeItem.fields.Brand.fields &&
                      storeItem.fields.Brand.fields.StoreImage &&
                      storeItem.fields.Brand.fields.StoreImage.value.src
                    }
                  />
                </NavLink>
              )
            )}
        </div>
      </div>
    );
  }
}

export default withSitecoreContext()(StoreList);