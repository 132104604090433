import React from "react";
import { Text } from '@sitecore-jss/sitecore-jss-react';

const ProductFinder = (props) => (
  <div>
    <p>ProductFinder Component</p>
    <Text field={props.fields.heading} />
  </div>
);

export default ProductFinder;
