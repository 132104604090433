import React from "react";
import {Image, Link, Text, withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';
import {NavLink} from "react-router-dom";
import { handleNavigationClick, observeProductFinder } from '../Tagging';

class HeaderMultilevel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
    };
  }

  componentDidMount(){
    observeProductFinder(this.props.sitecoreContext)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sitecoreContext.route.name !== this.props.sitecoreContext.route.name) {
      document.querySelector('.mobilemenu').classList.remove('show');
      this.setState({ menu: false });
    }
  }

  setSearchState(listItem, productPageLink) {
    var categoryList = [];
    var categoryNameList = [];
    categoryList.push(listItem.id.replace(/-/g, ''));
    categoryNameList.push(listItem.fields.title.value);
    sessionStorage.setItem('filterCategories', JSON.stringify(categoryList));
    sessionStorage.setItem('filterCategoriesName', JSON.stringify(categoryNameList));
    window.location.href = productPageLink && productPageLink.value.href + '?tagId=' + listItem.id.split('-').join('').toLowerCase();
  }

  render() {
    const outlineNone = {
      outline: 'none',
    };

    return (
      <div className="row_custom_small nav-hd">
        {/* Mobile Menu */}
        <div className="slicknav_menu">
          <p aria-haspopup="true" tabIndex="0" className="slicknav_btn" style={outlineNone}>
            <span className="slicknav_menutxt">MENU</span>
            <span
              className={this.state.menu ? 'slicknav_icon collapsed changeHamburger' : 'slicknav_icon collapsed'}
              data-toggle="collapse"
              data-target="#navigation">
              <span className="slicknav_icon-bar"></span>
              <span className="slicknav_icon-bar"></span>
              <span className="slicknav_icon-bar"></span>
            </span>
          </p>
          <ul className={'mobilemenu slicknav_nav slicknav_hidden collapse'} id="navigation">
            {this.props.fields.HeaderMenu &&
              this.props.fields.HeaderMenu.map((mainMenu, mainindex) => (
                <li className="producto-nav menu-item slicknav_parent helv_md" aria-haspopup="true" tabIndex="0" key={`first-${mainindex}`}>
                  {mainMenu.fields.InternalLink.value.href != '' && (
                    <NavLink
                      onClick={() => handleNavigationClick('header', mainMenu.fields.InternalLink.value.text)}
                      to={mainMenu.fields.InternalLink.value.href}
                      className="collapsed"
                      data-toggle="collapse"
                      data-target={`#menu${mainindex}`}>
                      {mainMenu.fields.InternalLink.value.text}
                    </NavLink>
                  )}
                  {mainMenu.fields.SubMenuLinks.length > 0 && (
                    <ul className="sub-menu slicknav_hidden collapse first_level" role="menu" aria-hidden="true" id={`menu${mainindex}`}>
                      {mainMenu.fields.SubMenuLinks &&
                        mainMenu.fields.SubMenuLinks.map((submenu, subindex) => (
                          <li className="col-md-4 child-nav slicknav_parent helv_md" key={subindex} aria-haspopup="true" tabIndex="0">
                            <p role="menuitem" className="slicknav_item slicknav_row" style={outlineNone}>
                              {submenu.fields.SubMenuLinks && submenu.fields.SubMenuLinks.length > 0 && (
                                <>
                                  {submenu.fields.InternalLink.value.href != '' && (
                                    <NavLink
                                      onClick={() =>
                                        handleNavigationClick(
                                          'header',
                                          mainMenu.fields.InternalLink.value.text + '::' + submenu.fields.InternalLink.value.text
                                        )
                                      }
                                      to={submenu.fields.InternalLink.value.href}
                                      className="collapsed"
                                      data-toggle="collapse"
                                      data-target={`#subind_${subindex}`}>
                                      {submenu.fields.InternalLink.value.text}
                                    </NavLink>
                                  )}
                                  <ul className="sub-menu slicknav_hidden collapse" id={`subind_${subindex}`}>
                                    {submenu.fields.SubMenuLinks &&
                                      submenu.fields.SubMenuLinks.map((sublink, sublinkindex) => (
                                        <li className="menu-item tiret__custom helv_md" key={sublinkindex}>
                                          {sublink.fields.InternalLink.value.href != '' && (
                                            <NavLink
                                              onClick={() =>
                                                handleNavigationClick(
                                                  'header',
                                                  mainMenu.fields.InternalLink.value.text +
                                                    '::' +
                                                    submenu.fields.InternalLink.value.text +
                                                    '::' +
                                                    sublink.fields.InternalLink.value.text
                                                )
                                              }
                                              to={sublink.fields.InternalLink.value.href}>
                                              {sublink.fields.InternalLink.value.text}
                                            </NavLink>
                                          )}
                                        </li>
                                      ))}
                                  </ul>
                                </>
                              )}

                              {/* Sub Menu For CategoriesList */}
                              {submenu.fields.CategoriesList && submenu.fields.CategoriesList.length > 0 && (
                                <>
                                  {submenu.fields.Title.value != '' && (
                                    <a className="collapsed" data-toggle="collapse" data-target={`#subind_${subindex}`}>
                                      <Text field={submenu.fields.Title} />
                                    </a>
                                  )}
                                  <ul className="sub-menu slicknav_hidden collapse" id={`subind_${subindex}`}>
                                    {submenu.fields.CategoriesList &&
                                      submenu.fields.CategoriesList.map((sublink, sublinkindex) => (
                                        <li className="menu-item tiret__custom helv_md" key={sublinkindex}>
                                          <a
                                            className="cat_nav helv_lt"
                                            onClick={this.setSearchState.bind(this, sublink, submenu.fields && submenu.fields.ProductPageLink)}>
                                            <Text field={sublink.fields && sublink.fields.title} />
                                          </a>
                                        </li>
                                      ))}
                                  </ul>
                                </>
                              )}
                            </p>
                          </li>
                        ))}
                    </ul>
                  )}
                </li>
              ))}
          </ul>
        </div>

        <div className="col-md-12 custom__height">
          <NavLink to="/" className="logo">
            <img src={this.props.fields.SiteLogo && this.props.fields.SiteLogo.value.src} className="img_custom_full" />
          </NavLink>
        </div>

        {/* Desktop Menu */}
        <div className="col-md-12 nav-cn">
          <nav>
            <ul className="menu-hd">
              {this.props.fields.HeaderMenu &&
                this.props.fields.HeaderMenu.map((mainMenu, mainindex) => (
                  <li
                    className={`${
                      mainMenu.fields.Title.value.toLowerCase() == 'products'
                        ? 'producto-nav'
                        : mainMenu.fields.Title.value.toLowerCase() == 'liveraw'
                        ? 'liveraw-nav'
                        : mainMenu.fields.Title.value.toLowerCase() == 'we'
                        ? 'nosotros-nav'
                        : ''
                    } ${mainMenu.fields.SubMenuLinks.length > 0 ? 'menu-item-has-children helv_md' : ''}`}
                    key={mainindex}>
                    {mainMenu.fields.InternalLink.value.href != '' && (
                      <NavLink
                        onClick={() => handleNavigationClick('header', mainMenu.fields.InternalLink.value.text)}
                        to={mainMenu.fields.InternalLink.value.href}
                        className="helv_std">
                        {mainMenu.fields.InternalLink.value.text}
                      </NavLink>
                    )}
                    {mainMenu.fields.SubMenuLinks.length > 0 && (
                      <ul className="sub-menu first_level">
                        {mainMenu.fields.SubMenuLinks &&
                          mainMenu.fields.SubMenuLinks.map((submenu, subindex) => (
                            <li
                              className={`menu-item ${mainMenu.fields.ShowSubmenu.value == '1' ? 'col-md-4 menu-item-has-children' : ''} ${
                                mainMenu.fields.SubMenuLinks.length == subindex + 1 ? 'no_border__custom' : ''
                              }`}
                              key={subindex}>
                              {submenu.fields.InternalLink.value.href != '' &&
                                (submenu.fields.InternalLink.value.href && submenu.fields.InternalLink.value.href.startsWith('http') === true ? (
                                  <Link
                                    onClick={() =>
                                      handleNavigationClick(
                                        'header',
                                        mainMenu.fields.InternalLink.value.text + '::' + submenu.fields.InternalLink.value.text
                                      )
                                    }
                                    field={submenu.fields.InternalLink}
                                    className="helv_rom"
                                  />
                                ) : (
                                  <NavLink
                                    onClick={() =>
                                      handleNavigationClick(
                                        'header',
                                        mainMenu.fields.InternalLink.value.text + '::' + submenu.fields.InternalLink.value.text
                                      )
                                    }
                                    to={submenu.fields.InternalLink.value.href}
                                    className="helv_rom">
                                    {submenu.fields.InternalLink.value.text}
                                  </NavLink>
                                ))}
                              {submenu.fields.SubMenuLinks && submenu.fields.SubMenuLinks.length > 0 && (
                                <ul className="sub-menu second_level">
                                  {submenu.fields.SubMenuLinks &&
                                    submenu.fields.SubMenuLinks.map((sublink, sublinkindex) => (
                                      <li className="menu-item" key={sublinkindex}>
                                        {sublink.fields.InternalLink.value.href != '' &&
                                          (sublink.fields.InternalLink.value.href &&
                                          sublink.fields.InternalLink.value.href.startsWith('http') === true ? (
                                            <Link
                                              onClick={() =>
                                                handleNavigationClick(
                                                  'header',
                                                  mainMenu.fields.InternalLink.value.text +
                                                    '::' +
                                                    submenu.fields.InternalLink.value.text +
                                                    '::' +
                                                    sublink.fields.InternalLink.value.text
                                                )
                                              }
                                              field={sublink.fields.InternalLink}
                                              className="helv_lt"
                                            />
                                          ) : (
                                            <NavLink
                                              onClick={() =>
                                                handleNavigationClick(
                                                  'header',
                                                  mainMenu.fields.InternalLink.value.text +
                                                    '::' +
                                                    submenu.fields.InternalLink.value.text +
                                                    '::' +
                                                    sublink.fields.InternalLink.value.text
                                                )
                                              }
                                              to={sublink.fields.InternalLink.value.href}
                                              className="helv_lt">
                                              {sublink.fields.InternalLink.value.text}
                                            </NavLink>
                                          ))}
                                      </li>
                                    ))}
                                </ul>
                              )}
                              {/* Sub Menu For CategoriesList */}
                              {submenu.fields.CategoriesList && submenu.fields.CategoriesList.length > 0 && (
                                <>
                                  <ul className="sub-menu">
                                    {submenu.fields.CategoriesList &&
                                      submenu.fields.CategoriesList.map((sublink, sublinkindex) => (
                                        <li className="menu-item" key={sublinkindex}>
                                          {submenu.fields.InternalLink &&
                                            submenu.fields.InternalLink.value &&
                                            submenu.fields.InternalLink.value.href && (
                                              <a
                                                className="cat_nav helv_lt"
                                                onClick={this.setSearchState.bind(this, sublink, submenu.fields.ProductPageLink)}>
                                                <Text field={sublink.fields && sublink.fields.title} />
                                              </a>
                                            )}
                                        </li>
                                      ))}
                                  </ul>
                                </>
                              )}
                            </li>
                          ))}
                        {mainMenu.fields.ShowLinkToOtherPage.value == '1' && (
                          <li className="col-md-12 menu-item menu-item-has-children buscar__custom">
                            {mainMenu.fields.LinkToOtherPage.value.href != '' &&
                              (mainMenu.fields.LinkToOtherPage.value.href &&
                              mainMenu.fields.LinkToOtherPage.value.href.startsWith('http') === true ? (
                                <Link field={mainMenu.fields.LinkToOtherPage} className="btn btn_custom helv_rom btn_custom_green text-uppercase" />
                              ) : (
                                <NavLink to={mainMenu.fields.LinkToOtherPage.value.href} className="helv_rom">
                                  {mainMenu.fields.LinkToOtherPage.value.text}
                                </NavLink>
                              ))}
                          </li>
                        )}
                      </ul>
                    )}
                  </li>
                ))}
            </ul>
          </nav>
        </div>
      </div>
    );
  }
}

export default withSitecoreContext()(HeaderMultilevel);