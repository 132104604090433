import packageJson from '../../../package.json';
import SCJSS from '../../temp/config';

class Context {

    static getIndexName(args) {
        return "sitecore_" + args.sitecoreContext.route.databaseName + "_" + packageJson.config.appName.split('-').join('_') + "_index";
    }

    static getGraphQLEndPoint(){
        return SCJSS.sitecoreApiHost + "/api/" + packageJson.config.appName + "?sc_apikey=" + SCJSS.sitecoreApiKey + "&";
    }

}

export default Context;
