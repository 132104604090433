import React from 'react';
import { Text, Image, Link, RichText } from '@sitecore-jss/sitecore-jss-react';
import StarRating from '../StarRating';
import { loader as gqlLoader } from 'graphql.macro';
import GraphQLData from '../../lib/GraphQLData';
import NavLink from 'react-router-dom/es/NavLink';
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/src/owl.carousel.css';
import productMas from '../../assets/img/product-mas.svg';
import logo from '../../assets/img/placeholder.png';
import i18next from 'i18next';
import { productClickTagging } from '../Tagging';

const RelatedProductsQuery = gqlLoader('./RelatedProductsQuery.graphql');

class BiolageRelatedProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      myItem: 4,
      itemdata: [],
      productVariantURL: '',
    };
    this.resizeItem = this.resizeItem.bind(this);
  }

  componentDidMount() {
    this.resizeItem();
    window.addEventListener('resize', this.resizeItem);
  }

  resizeItem() {
    if (window.screen.width >= 320 && window.screen.width <= 425) {
      this.setState({
        myItem: 1,
      });
    } else if (window.screen.width >= 426 && window.screen.width <= 768) {
      this.setState({
        myItem: 2,
      });
    } else {
      this.setState({
        myItem: 4,
      });
    }
  }

  render() {
    const graphQLResult = this.props.relatedProductsQuery;
    const { datasource } = graphQLResult;
    const options = {
      items: this.state.myItem,
      loop: true,
      lazyLoad: true,
      nav: false,
      dots: false,
    };

    return (
      <div className="row_custom_small related__product__custom">
        <div className="col-md-12 pad ratedCustom text-uppercase pb-2 pageTitleRelated">
          <Text field={this.props.fields.RelatedProductsHeading} tag="h1" />
        </div>
        {datasource != undefined && (
          <>
            <button className="btn chevrons_bestrated prev" onClick={() => this.refs.related.prev()}>
              <i className="fas fa-chevron-left" />
            </button>
            <OwlCarousel ref="related" options={options} className={'col-md-10 relatedProd'}>
              {datasource &&
                datasource.relatedProductsList.targetItems.map((productItem, index) => (
                  <div key={`item-${index}`} className="portfolio-item text-center">
                    <span className="plus__custom" data-toggle="modal" data-target={`#__${productItem.id.substr(15)}`}>
                      <img src={productMas} width="22" height="25" alt="" className="img_custom_full" />
                    </span>
                    <NavLink
                      to={productItem && productItem.url}
                      onClick={(e) => productClickTagging(e)}
                      data-gtm-name={productItem.title.value}
                      data-gtm-id={productItem.id}
                      data-gtm-category={productItem.url.split('/')[2]}
                      data-gtm-list="related-products"
                      data-gtm-position={index + 1}
                      class="product_impression">
                      <div className="portfolio-link">
                        <div className="arround_grey" />
                        {productItem.image1.src === null ? (
                          <img src={logo} alt="placeholder" className="img-fluid img_custom_full placeholderCustom" />
                        ) : (
                          <img src={productItem.image1.src} className="img-fluid img_custom_full" />
                        )}
                        <div className="portfolio-caption min_font">
                          <Text field={productItem.title.jss} tag="h2" className={'helv_md'} />
                          <StarRating value={productItem.rating.numberValue} />
                        </div>
                      </div>
                      <button className="btn btn_custom_oro">{i18next.t('SeeMoreButton')}</button>
                    </NavLink>
                  </div>
                ))}
            </OwlCarousel>
            <button className="btn chevrons_bestrated next" onClick={() => this.refs.related.next()}>
              <i className="fas fa-chevron-right" />
            </button>
          </>
        )}
        {datasource &&
          datasource.relatedProductsList.targetItems.map((productItem, index) => (
            <div
              className="modal fade customPopUpBestrated"
              id={`__${productItem.id.substr(15)}`}
              tabIndex="-1"
              role="dialog"
              aria-labelledby="label_of_modal"
              aria-hidden="true"
              key={`related-${index}`}>
              <div className="modal-dialog modal-dialog-centered modal-xl">
                <div className="modal-content">
                  <button type="button" className="close text-right" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                  </button>
                  <div className="modal-body">
                    <div className="col-md-5 text-center">
                      {productItem.image1.src === null ? (
                        <img src={logo} alt="placeholder" className="img-fluid img_custom_full placeholderCustom" />
                      ) : (
                        <img src={productItem.image1 && productItem.image1.value.src} alt={productItem.image1.alt} className="img_custom_full" />
                      )}
                    </div>
                    <div className="col-md-7 text-left">
                      <h2 className="popup-title_item helv_bd">{productItem.title.value}</h2>
                      <span className="litro__custom helv_lt">
                        {productItem.children &&
                          productItem.children.map((child, idx) => {
                            if (child.productVariantLink) {
                              this.state.productVariantURL = child.productVariantLink.url;
                            }
                            let childVariants = child && child.title && child.title.value;
                            return (
                              <React.Fragment key={idx}>
                                {childVariants}
                                {idx < productItem.children.length - 1 && <>,&nbsp;</>}
                              </React.Fragment>
                            );
                          })}
                      </span>
                      <StarRating value={productItem.rating.numberValue} />
                      <RichText field={productItem.longDescription} className="pop__description helv_lt" />
                      {this.state.productVariantURL &&
                        this.state.productVariantURL !== '' &&
                        (this.state.productVariantURL.startsWith('http') === true ? (
                          <a href={this.state.productVariantURL} className="btn btn_custom_oro helv_lt" target="_blank">
                            {i18next.t('BuyButton')}
                          </a>
                        ) : (
                          <NavLink to={this.state.productVariantURL} className="btn btn_custom_oro helv_lt" target="_blank">
                            {i18next.t('BuyButton')}
                          </NavLink>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    );
  }
}

export default GraphQLData(RelatedProductsQuery, { name: 'relatedProductsQuery' })(BiolageRelatedProducts);
