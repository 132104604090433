import React from "react";

class CheckOutVideo extends React.Component {

    constructor(props){
        super(props);
    }

    render(){
        return(
            <div className="row_custom_small">
                <div className="col-md-12 text-center video__pad">
                    <div className="embed-responsive embed-responsive-16by9">
                        <iframe width='500' height='281' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                                frameborder='0' className='embed-responsive-item' src={ this.props && this.props.fields && this.props.fields.VideoUrl && this.props.fields.VideoUrl.value} allowFullScreen></iframe>
                    </div>
                </div>
            </div>
        )
    }

}

export default CheckOutVideo;
